<template>
    <el-drawer
        v-model="drawer"
        :direction="rtl"
        @closed="handleClose()"
        @opened="handleOpen()"
    >
        <el-scrollbar height="85vh">
            <!-- <h2 class="font-xx-large font-weight-bold ml-3">
                {{ user.fistName }} {{ user.lastName }}
            </h2> -->
            <el-form
                class="p-4"
                label-position="top"
                label-width="100px"
                :model="vehicle"
                ref="vehicle"
                :rules="rules"
            >
                <el-form-item label="Tipo de Vehículo" prop="vehicleTypeId">
                    <el-select class="w-75" v-model="vehicle.vehicleTypeId">
                        <el-option
                            v-for="item in vehicleTypes"
                            :key="item.id"
                            :value="item.id"
                            :label="item.name"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Número de Chasis" prop="chassisNumber">
                    <el-input
                        class="w-75"
                        v-model="vehicle.chassisNumber"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Número de Registro" prop="registration">
                    <el-input
                        class="w-75"
                        v-model="vehicle.registration"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Marca" prop="brand">
                    <el-input class="w-75" v-model="vehicle.brand"></el-input>
                </el-form-item>
                <el-form-item label="Modelo" prop="model">
                    <el-input class="w-75" v-model="vehicle.model"></el-input>
                </el-form-item>
                <el-form-item label="Año" prop="year">
                    <el-input class="w-75" v-model="vehicle.year"></el-input>
                </el-form-item>
                <el-form-item label="Color" prop="color">
                    <el-input class="w-75" v-model="vehicle.color"></el-input>
                </el-form-item>
                <el-form-item label="Volumen" prop="availableVolumeM3">
                    <el-input
                        class="w-75"
                        v-model="vehicle.availableVolumeM3"
                    ></el-input>
                </el-form-item>
                <div class="w-75">
                    <el-button @click="$emit('drawerclosed')" round
                        >Cancelar</el-button
                    >
                    <el-button
                        class="pull-end bg-yellow"
                        style="float: right"
                        round
                        @click="manageVehicle()"
                        >Guardar</el-button
                    >
                </div>
                <el-divider></el-divider>
                <h3 class="font-large font-weight-bold font-italic">
                    Herramientas
                </h3>
                <el-button
                    type="default"
                    style="padding: 10px"
                    size="mini"
                    icon="el-icon-delete"
                    @click="handleDeleteVehicle"
                ></el-button>
            </el-form>
        </el-scrollbar>
    </el-drawer>
</template>

<script>
import { defineComponent } from "vue";
import lookoutService from "@/services/lookoutService";
import vehicleService from "@/services/vehicleService";
import {
    ElDrawer,
    ElButton,
    ElForm,
    ElScrollbar,
    ElSelect,
    ElOption,
    ElDivider,
    ElMessageBox,
    ElMessage,
} from "element-plus";
export default defineComponent({
    components: {
        ElDrawer,
        ElButton,
        ElForm,
        ElScrollbar,
        ElSelect,
        ElOption,
        ElDivider,
    },
    data() {
        return {
            drawer: false,
            vehicle: {},
            vehicleTypes: [],
            brands: [],
            rules: {
                vehicleTypeId: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                chassisNumber: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                registration: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                brand: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                model: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                year: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                color: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                availableVolumeM3: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        vehicleId: {
            type: String,
            default: null,
        },
    },
    watch: {
        isActive(value) {
            this.drawer = value;
        },
    },
    methods: {
        handleClose() {
            this.$emit("drawerclosed");
            this.$refs["vehicle"].resetFields();
        },
        async handleDeleteVehicle() {
            ElMessageBox.confirm(
                `¿Deseas borrar permanentemente este vehículo?`,
                {
                    confirmButtonText: "Borrar",
                    cancelButtonText: "Cancelar",
                    type: "danger",
                    confirmButtonClass: "bg-danger",
                }
            )
                .then(async () => {
                    await this.deleteVehicle(this.vehicle.id);
                })
                .catch(() => {
                    ElMessage({
                        type: "info",
                        message: "Acción cancelada",
                    });
                });
        },
        async deleteVehicle(id) {
            await vehicleService
                .deleteVehicle(id)
                .then(() => {
                    ElMessage({
                        type: "success",
                        message: "Vehículo eliminado exitosamente",
                    });
                    this.$emit("refreshData");
                })
                .catch(() => {
                    ElMessage({
                        type: "danger",
                        message: "Ha ocurrido un error inesperado.",
                    });
                });
        },
        async getVehicleType() {
            const data = await lookoutService.getVehicleType();
            this.vehicleTypes = data.data;
        },
        async getBrand() {
            // const data = await lookoutService.getApplicationUserType();
            // this.brand = data.data;
        },
        async manageVehicle() {
            let that = this;
            this.$refs["vehicle"].validate(async (valid) => {
                if (valid) {
                    if (that.editMode) {
                        await that
                            .updateVehicle(this.vehicle)
                            .then(() => {
                                ElMessage({
                                    type: "success",
                                    message:
                                        "Vehículo actualizado exitosamente",
                                });
                                this.$emit("refreshData");
                            })
                            .catch(() => {
                                ElMessage({
                                    type: "danger",
                                    message: "Ha ocurrido un error inesperado.",
                                });
                            });
                    } else {
                        await that
                            .addVehicle(this.vehicle)
                            .then(() => {
                                ElMessage({
                                    type: "success",
                                    message: "Vehículo agregado exitosamente",
                                });
                                this.$emit("refreshData");
                            })
                            .catch((e) => {
                                console.log(e);
                                ElMessage({
                                    type: "danger",
                                    message: "Ha ocurrido un error inesperado.",
                                });
                            });
                    }
                } else {
                    return false;
                }
            });
        },
        async addVehicle(item) {
            var payload = {
                vehicleTypeId: item.vehicleTypeId,
                chassisNumber: item.chassisNumber,
                registration: item.registration,
                brand: item.brand,
                model: item.model,
                year: item.year,
                color: item.color,
                availableVolumeM3: item.availableVolumeM3,
            };
            await vehicleService.addVehicle(payload);
            this.$emit("refreshData");
        },
        async updateVehicle(item) {
            var payload = {
                id: item.id,
                vehicleTypeId: item.vehicleTypeId,
                chassisNumber: item.chassisNumber,
                registration: item.registration,
                brand: item.brand,
                model: item.model,
                year: item.year,
                color: item.color,
                availableVolumeM3: parseFloat(item.availableVolumeM3),
                active: item.active,
            };
            await vehicleService.updateVehicle(payload);
            this.$emit("refreshData");
        },
        async handleOpen() {
            if (this.editMode) {
                var data = await vehicleService.getVehicle({
                    id: this.vehicleId,
                });
                this.vehicle = data.data;
                this.vehicle.id = this.vehicleId;
            } else {
                this.vehicle = [];
            }
        },
    },
    async mounted() {
        await this.getVehicleType();
        await this.getBrand();
    },
});
</script>
<style lang="scss">
.el-form-item__label {
    font-weight: bold !important;
}
.el-icon {
    --font-size: 31px;
}
</style>
