import { api } from "../core/api";

export default {
    getGenres() {
        return api.get("/Lookup/GetGenres");
    },
    getApplicationUserType() {
        return api.get("/Lookup/GetApplicationUserType");
    },
    getVehicleType() {
        return api.get("/Lookup/VehicleType");
    },
    getVehicleTypes() {
        return api.get("/Lookup/VehicleType");
    },
    getWorkSessions() {
        return api.get("/Lookup/WorkSession");
    },
};
