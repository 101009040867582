import { api } from "../core/api";

export default {
    getVehicles(params) {
        return api.get("/Vehicle/GetAll", { params: params });
    },
    getVehicle(param) {
        return api.get("/Vehicle/Get", { params: param });
    },
    addVehicle(vehicle) {
        return api.post("/Vehicle/Add", vehicle);
    },
    updateVehicle(vehicle) {
        console.log(vehicle);
        return api.patch("/Vehicle/Update", vehicle);
    },
    toogleActivate(id) {
        return api.put(`/Vehicle/ToogleActivate?id=${id}`);
    },
    deleteVehicle(id) {
        return api.delete(`/Vehicle/Delete?id=${id}`);
    },
};
