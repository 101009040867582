<template>
    <div class="home">
        <vehicle-list
            @addNewVehicle="addNewVehicle()"
            :key="userComponentKey"
            @editVehicle="editVehicle"
        />
        <manage-vehicle
            :edit-mode="editMode"
            :is-active="drawer"
            :vehicle-id="vehicleId"
            @drawerclosed="drawer = false"
            @refreshData="refreshData"
        />
    </div>
</template>
<script>
// @ is an alias to /src
// import api from "../services/userService";
import manageVehicle from "./components/manageVehicle.vue";
import vehicleList from "./components/vehicleList.vue";

export default {
    name: "Home",
    data() {
        return {
            drawer: false,
            editMode: false,
            vehicleId: null,
            userComponentKey: 0,
        };
    },
    components: {
        manageVehicle,
        vehicleList,
    },
    methods: {
        addNewVehicle() {
            this.editMode = false;
            this.vehicleId = null;
            this.drawer = true;
        },
        refreshData() {
            this.userComponentKey += 1;
            this.drawer = false;
        },
        editVehicle(id) {
            this.editMode = true;
            this.vehicleId = id;
            this.drawer = true;
        },
    },
    async mounted() {
        // let users = await api.getUsers({
        //     pageSize: 1,
        //     pageIndex: 1,
        //     role: "delivery",
        // });
    },
};
</script>
